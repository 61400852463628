/* =Publications
----------------------------------------------------------*/

.publication {
  @include clearfix();
  margin-bottom: 40px;
  padding-bottom: 50px;
  border-bottom: 1px solid $wcm-border-gray;
}

.publication:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.publication__edition {
  margin-bottom: 0;
}

.publication__title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}

.publication__teaser {
  margin-bottom: 30px;
}

.publication__download-pdf {
  @include clearfix();
  font-size: 14px;
  float: left;
  margin-right: 30px;
}

.button-icon {
  display: block;
  width: 30px;
  height: 30px;
  float: left;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-image: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg);
  background-size: 1700%;
}

.button-icon--pdf {
  background-position: -33px -95px;
}

.publication__view-online {
  @include clearfix();
  font-size: 14px;
  float: left;
}

.publication__view-online a.external-link {
  &:after {
    content: '';
  }
}

.button-icon--online {
  background-position: -290px 0px;
}

.view-publications .view-footer {
  margin-bottom: 20px;
}