/**
 * Styles related to the Video and Photography section
 *
 */


/* =Photo Sets
----------------------------------------------------------*/

.photo-set-list [class^="col-"],
.photo-set-list [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

.photo-set {
  margin: 0 0 30px;
}

.photo-set:nth-child(odd) {
  @include breakpoint($md) {
    clear: both;
    padding-right: 10px;
  }
}

.photo-set:nth-child(even) {
  @include breakpoint($md) {
    padding-left: 10px;
  }
}

.photo-set__title {
  margin-top: 5px;
}

/* =Vimeo Albums
----------------------------------------------------------*/

.pane-bundle-wcmc-vimeo, .page-node-56 .pane-bundle-video {
  margin-bottom: 40px;
  padding-bottom: 50px;
  border-bottom: 1px solid $wcm-border-gray;
}

