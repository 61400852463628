/**
 * Styles for various Newsroom components
 *
 */

/* =Primary Navigation
----------------------------------------------------------*/

#primary-nav .level-1 {
  font-size: 13px;
  width: 16%;
  line-height: 1.3;
  @include breakpoint($md) {
    width: (100% / 6);
    font-size: 14px;
  }
  @include breakpoint($lg) {
    font-size: 16px;
  }
}

// News
#primary-nav  .menu-mlid-1949 {
  width: 10%;
  @include breakpoint($md) {
    width: 9%;
  }
}

// Video & Photography
#primary-nav  .menu-mlid-1487 {
  width: 24%;
  @include breakpoint($md) {
    width: 22%;
  }
  @include breakpoint($lg) {
    width: 18%;
  }
}

/* =Sidebar
----------------------------------------------------------*/

.newsroom-cta-social .wcm-cta__tile {
  width: auto;
  height: 60px;
  border: none;
  float: none;
}

.newsroom-cta-social  .wcm-cta__tile .cta-icon {
  width: 30px;
  height: 30px;
}

.wcm-newsroom-home-social-media-icons .wcm-cta__tile:after,
.newsroom-cta-social .wcm-cta__tile:after {
  box-shadow: none;
}

/* =Doormat Pane
----------------------------------------------------------*/

.pane-bundle-doormat {
  background: $wcm-bg-gray;
  .pane-title {
    font-size: 24px;
    text-align: center;
    margin: 0 0 30px;
  }
}

.pane-bundle-doormat .container {
  padding: 20px 30px 30px;
  @include breakpoint($md) {
    padding: 20px 15px 20px;
  }
}

.press-doormat {
  @include clearfix();
  h3 > a:before {
    content: '';
    display: block;
    width: 50px;
    height: 50px;
    float: left;
    margin-right: 20px;
    background-repeat: no-repeat;
    background-image: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg);
    // The original background is 1264px 384px, each icon is 64px x 64px...so we scale it by .78124 (50/64)
    background-size: 970px 285px;
    @include breakpoint($md-only) {
      float: none;
      margin: 0 auto 20px;
    }
    @include breakpoint($lg) {
      float: left;
      margin-right: 10px;
    }
  }
  .doormat-entry--1 h3 > a:before {
    background-position: -193px -58px;
  }
  .doormat-entry--2 h3 > a:before {
    background-position: -193px 1px;
  }
  .doormat-entry--3 h3 > a:before {
    background-position: -133px -58px;
  }
  .doormat-entry__description {
    padding-left: 60px;
    @include breakpoint($md-only) {
      padding-left: 0;
    }
  }
}

/* =Spotlights
----------------------------------------------------------*/

.field-name-field-basic-spotlight-items {
  margin: 0 0 20px;
}

.pane-bundle-spotlight .panopoly-spotlight-wrapper {
  max-width: none;
  width: 100%;
}

.pane-bundle-spotlight .panopoly-spotlight-info {
  padding: 10px 20px 15px;
}

.pane-bundle-spotlight .panopoly-spotlight-wrapper p {
  line-height: 1.5;
}

/* =Default Select Elements
----------------------------------------------------------*/

.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid $wcm-border-gray;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px;
}

/* =Forms
----------------------------------------------------------*/

.form-select {
  max-width: 600px;
}

.views-exposed-form .views-exposed-widget {
  float: none;
  @include breakpoint($sm) {
    float: left;
  }
}

.form-group .select2-container {
  min-width: 110px;
}

.views-widget-filter-field_story_type_target_id .select2-container {
  min-width: 280px;
}

.views-widget-filter-field_story_mission_target_id .select2-container {
  min-width: 170px;
}

.webform-component .select2-container {
  @include breakpoint($sm) {
    max-width: 50%;
  }
}

.webform-component-time .form-select {
  display: inline;
  //width: 80px;
  background-image: none;
  padding-right: 0;
  @include breakpoint($xs) {
    font-size: 13px;
    padding: 5px;
  }
  @include breakpoint($sm) {
    width: 80px;
  }
}

.webform-component-time .radio input[type="radio"] {
  margin-top: 14px;
  margin-left: -16px;
}

.webform-container-inline.webform-datepicker div.form-item.form-type-select {
  display: none;
}

#edit-title-wrapper label {
  display: block;
}

#edit-title {
  @include breakpoint($sm) {
    width: 500px;
  }
}

#edit-submit-news-stories {
  margin-top: 30px;
}

.view-news-stories #autocomplete {
  font-size: 13px;
}

/* =Footer
----------------------------------------------------------*/

.footer-site-info__office {
  font-weight: 700;
}

.footer-nav {
  @include breakpoint($md) {
    margin: 0 0 20px;
  }
}

.footer-nav .level-1 {
  @include breakpoint($md) {
    width: (100% / 6);
    float: left;
    padding: 0 10px 5px;
  }
}

/* =Misc
----------------------------------------------------------*/

.social-share__print a:before {
  content: '\e814';
}

.btn--dark-orange {
  border-radius: 20px;
  font-size: 13px;
  background-color: $wcm-dark-orange;
  color: $wcm-white;
  display: inline-block;

  &:hover, &:focus {
    background-color: $wcm-red;
    border-color: $wcm-red;
  }
}

.btn--mobile-app {
  span {
    display: block;
    float: left;
    background-repeat: no-repeat;
    background-image: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg);
    // The original background is 1264px 384px, each icon is 64px x 64px...so we scale it by .3125 (20/64)
    background-size: 395px 120px;
    background-position: -50px -25px;
    width: 20px;
    height: 20px;
  }
  &:hover {
    span {
      display: none;
    }
  }
}

.horizontal-tabs {
  clear: both;
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

.pager--infinite-scroll {
  li a {
    border-radius: 20px;
    border: 4px solid $wcm-bright-orange;
    padding: 6px 20px;
    font-size: 13px;
    width: 220px;

    &:hover, &:focus {
      border-color: $wcm-red;
      background-color: $wcm-dark-orange;
      color: $wcm-white;
    }
  }
}




