/**
 * Styles related to the social media section.
 *
 */


.wcm-newsroom-social-media-icons {
	@include clearfix();
	margin: 20px 0;

    .col-md-2 {
        @include breakpoint( (max: 991px) ) {
            width: 50%;
            float: left;
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}

.wcm-cta__tile-large {
    border-width: 0;
    float: none;
    margin: 0 auto;
    @include breakpoint($xs) {
        width: 140px;
        height: 140px;
    }
    width: 150px;
    height: 150px;

    &:nth-child(1) {
        border-width: 0;
    }

    .cta-icon {
    	width: 75px;
    	height: 75px;
    }

    &:hover {
        border-width: 1px;
    }

    .wcm-cta__detail {
        color: $wcm-med-gray;
        font-size: 15px;
        line-height: 24px;
    }
}


.twitter-timeline {
  margin-bottom: 30px !important;
}