/* =In the Media
----------------------------------------------------------*/

.pane-in-the-media-panel-pane-1 {
  .ex-media {
    margin: 0 0 20px;
    padding: 0 0 20px;
    border-bottom: 1px solid $wcm-border-gray;
  }
  .ex-media:last-child {
    border: none;
  }
}

.pane-in-the-media-panel-pane-2 h3.pane-title {
  font-size: 30px;
}

.ex-media__image {
  max-width: 240px;
  margin: 10px 0;
}

.ex-media__date {
  text-transform: uppercase;
  color: $wcm-med-gray;
  font-family: $wcm-bold;
  font-size: 13px;
  letter-spacing: 2px;
  margin: 20px 0;
}

.ex-media__contact {
  font-size: 12px;
  margin-bottom: 20px;
}