/**
 * Styles related to components on the Newsroom homepage.
 *
 */

/* =Home Layout
----------------------------------------------------------*/

.panel-newsroom-home .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.front .main-info-section {
  margin: 40px 0;
}

.front .btn--wcm {
  margin-top: 40px;
}

/* =Home Callouts
----------------------------------------------------------*/

.home-callouts {
  overflow: hidden;
  @include clearfix();
}

.row-eq-height {
  @include breakpoint($sm) {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
  }
}

.home-callouts__main {
  @include breakpoint($sm) {
    border-right: 1px solid #fff;
  }
}

.home-callouts__side {
  @include breakpoint($sm) {
    border-left: 1px solid #fff;
  }
}

.home-callout--two .home-featured-news-story {
  @include breakpoint($sm) {
    position: relative;
    top: -2px;
  }
}

.home-callout--three .home-featured-news-story {
  @include breakpoint($sm) {
    //border-top: 2px solid #fff;
  }
}

.panel-newsroom-home img {
  width: 100%;
  max-width: none;
  height: auto;
}

// .home-callout .mask {
//   &:hover {
//     background: rgba(0,0,0,0.75);
//   }  
// }  10-28-20 by Joe Miller new code below - reducing dark hover

.home-callout .mask {
  &:hover {
    background: rgba(0,0,0,0.5);
  }
}

.home-featured-news-story {
  position: relative;
  margin-bottom: 2px;
  @include breakpoint($sm) {
    margin-bottom: 0;
  }
}

.home-callout .featured-news-story__title {
  color: #fff;
  position: absolute;
  margin: 0 20px;
  z-index: 100;
}

.home-callout--one .featured-news-story__title {
  bottom: 8%;
  left: 30px;
  text-shadow: 0 3px 9px #333;
  @include breakpoint($sm) {
    font-size: 30px;
  }
}

.home-callout--two .featured-news-story__title,
.home-callout--three .featured-news-story__title {
  bottom: 8%;
  left: 1%;
  text-shadow: 0 3px 9px #333;
  @include breakpoint($sm) {
    font-size: 16px;
  }
  @include breakpoint($md) {
    font-size: 16px;
  }
}

/* =Home Latest News Stories
----------------------------------------------------------*/

.pane-news-stories-panel-pane-6 {
  h3 {
    margin: 0 0 20px;
  }
}

.home-latest-news-story {
  overflow: hidden;
  position: relative;
  max-width: 640px;
  margin: 0 0 3%;
  padding: 0;
  @include breakpoint($md) {
    max-width: none;
    width: 48%;
    float: left;
  }
}

.home-latest-news-story.views-row-odd {
  @include breakpoint($md) {
    margin-right: 3%;
  }
}

.latest-news-story__image {
  overflow: hidden;
}

.latest-news-story__image img {
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.25);
  }
}

.home-latest-news-story .featured-news-story__title {
  font-size: 15px;
  margin: 0;
  a {
    color: $wcm-dark-orange;
    &:hover {
      color: $wcm-bright-orange;
    }
  }
}

.home-latest-news-story .news-story__date {
  margin: 10px 0 5px;
  font-size: 13px;
}

.home-latest-news-stories .view-footer {
  clear: both;
}

/* =Main Sidebar
----------------------------------------------------------*/

.main-sidebar {
  margin: 40px 0 0;
  font-size: 14px;
}

.wcm-newsroom-home-social-media-icons {
  position: relative;
  border-top: 1px solid $wcm-border-gray;
  margin: 20px 0 0;
  padding: 20px 0 0;
  h4 {
    margin-bottom: 20px;
  }
  .social-media-feeds  {
    &:after {
      content: '\e80d';
      color: $wcm-bright-orange;
      padding-left: 10px;
      font-size: 60%;
      @include fontello();
      text-decoration: none;
      line-height: 1.5;
    }
    @include breakpoint($lg) {
      position: absolute;
      top: 40px;
      right: 80px;
    }
  }
  .wcm-cta__tile {
    width: 46px;
    height: 46px;
    border: none;
    margin-right: 5px;
  }
  .wcm-cta__tile .cta-icon {
    width: 35px;
    height: 35px;
  }
  .wcm-cta__detail {
    padding-top: 6px;
  }
}

