/**
 *  Styles targeting the Staff Directory and Media Relations Contact Pane
 */


/* =Staff Teasers
----------------------------------------------------------*/

.staff__name {
  font-weight: 700;
}

.staff__title,
.staff__phone,
.staff__email {
  font-size: 13px;
}

.staff__beats {
  font-size: 12px;
  margin-top: 10px;
  span {
    font-weight: 700;
  }
}

.staff-media-relations,
.staff-directory {
  .grid-title,
  .grid-item {
    margin-bottom: 20px;
  }
}

/* =Media Relations Pane
----------------------------------------------------------*/

.staff-media-relations {
  margin: 20px 0 40px;
  padding-bottom: 80px;
  border-bottom: 1px solid $wcm-border-gray;
}


