/**
 * Styles related to Photo Stories
 *
 */

.photo-story {
  position: relative;
}

.paragraphs-items-field-photo-story-set .cycle-slideshow {
  background: none;
}

.paragraphs-items-field-photo-story-set .thumbnail-controller {
  background: #f7f7f7;
  padding: 30px;
  overflow: hidden;
  @include clearfix();
}

.paragraphs-items-field-photo-story-set #thumbnail-pager a {
  display: inline-block;;
  margin: 0 20px 10px 0;
}

.photo-story__caption {
  margin: 40px 0;
}

.paragraphs-items-field-photo-story-set {

  .thumbnail-controller__wrap {
    float: left;
    position: relative;
    left: 50%;
  }

  .thumbnail-controller__wrap-inner {
    position: relative;
    left: -50%;
  }

  .thumbnail-controller__pager {
    display: block;
    float: left;
    padding: 0 10px;
    @include breakpoint($md) {
      width: 5%;
    }
    &:after {
      content: '\e802';
      color: $wcm-bright-orange;
      font-size: 24px;
      vertical-align: top;
      @include fontello();
      text-decoration: none;
      line-height: 1.5;
    }
  }

  .thumbnail-controller__pager.previous {
    &:after {
      content: '\e810';
      @include breakpoint($md) {
        padding: 10px 10px 0 0;
      }
    }
  }

  .thumbnail-controller__pager.next {
    &:after {
      content: '\e802';
      @include breakpoint($md) {
        padding: 10px 0 0 10px;
      }
    }
  }

  #thumbnail-pager {
    display: none;
    @include breakpoint($md) {
      float: left;
      display: block;
      width: 90%;
    }
  }

  #mobile-slide-counter {
    float: left;
    padding: 3px 20px;
    @include breakpoint($md) {
      display: none !important;
    }
  }

}

/* =Previous / Next Arrow Overlays
----------------------------------------------------------------------------------*/

.paragraphs-items-field-photo-story-set {
  .cycle-prev, .cycle-next {
    display: none;
    @include breakpoint($sm) {
      position: absolute;
      top: 0;
      opacity: 0;
      filter: alpha(opacity=0);
      width: 10%;
      z-index: 1002;
      height: 100%;
      cursor: pointer;
    }
    @include breakpoint($md) {
      display: block;
    }
  }

  .cycle-prev {
    left: 0;
    background: url("/profiles/wcmc/modules/custom/wcmc_carousel/images/carousel-prev.png") 35% 20% no-repeat;
    @include breakpoint($lg) {
      background-position: 35% 30%;
    }
  }

  .cycle-next {
    right: 0;
    background: url("/profiles/wcmc/modules/custom/wcmc_carousel/images/carousel-next.png") 65% 20% no-repeat;
    @include breakpoint($lg) {
      background-position: 35% 30%;
    }
  }

  .cycle-prev:hover,
  .cycle-next:hover {
    opacity: .7;
    filter: alpha(opacity=70);
  }
}


