/* =News Story Nodes
----------------------------------------------------------*/

.social-share a {
  border: none;
}

.pane-node-field-story-deck,
.pane-node-field-photo-story-deck {
  font-size: 20px;
  color: $wcm-med-gray;
  line-height: 1.3;
  padding-bottom: 10px;
}

.pane-node-field-story-post-date,
.related-news h3 {
  text-transform: uppercase;
  color: $wcm-med-gray;
  font-family: $wcm-bold;
  font-size: 13px;
  letter-spacing: 2px;
  padding-bottom: 10px;
}

.pane-node-field-story-featured-image {
  margin: 25px 0;
}

.related-news {
  margin: 40px 0;
  padding: 10px 20px 20px;
  border: 1px solid $wcm-bright-orange;
}

/* =News Story Index
----------------------------------------------------------*/

.pane-news-stories-panel-pane-1 h3 {
  margin-top: 0;
}

.select2-results__option:first-child {
  font-style: italic;
  border-bottom: 2px solid #ddd;
}

.news-story {
  max-width: 640px;
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid $wcm-border-gray;
  @include breakpoint($md) {
    max-width: none;
  }
}

.news-story:last-child {
  @include breakpoint($md) {
    border-bottom: none;
  }
}

.news-story__title {
  @include breakpoint($md) {
    margin-top: 0;
  }
}

.news-story__date {
  text-transform: uppercase;
  color: $wcm-med-gray;
  font-family: $wcm-bold;
  font-size: 13px;
  letter-spacing: 2px;
  margin: 5px 0 20px;
}

.news-story__read-more {
  padding-top: 7.5px;
  a:after {
    content: '\e80d';
    color: $wcm-bright-orange;
    padding: 5px 0 0 10px;
    font-size: 60%;
    vertical-align: top;
    @include fontello();
    text-decoration: none;
    line-height: 1.5;
  }
}

.news-story__tags,
.story-tags {
  margin: 34px 0 0;
  font-size: 12px;
  background: url(../images/tag.png) left center no-repeat;
  background-size: 15px 15px;
  padding-left: 22px;
}

.news-story__type {
  font-size: 12px;
  text-transform: uppercase;
  font-family: $wcm-bold;
  padding: 5px 10px 0;
  margin-bottom: 3px;
  background: $wcm-dark-orange;
  max-width: 640px;
  a {
    color: $wcm-white;
    border-bottom: none;
    &:hover {
      color: $wcm-white;
      text-decoration: none;
    }
  }
}
/*
.news-story__type--1 {
  background: $wcm-bright-orange;
}

.news-story__type--4 {
  background: $wcm-yellow;
}*/

/* =Index Filters
----------------------------------------------------------*/

.view-news-stories .views-exposed-form {
  margin: 10px 0 60px;

  .widget-label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
  }
}

/* =Featured News Stories Tiles
----------------------------------------------------------*/

.featured-news-stories {
  margin: 0 0 10px;
  @include clearfix();
}

.featured-news-story {
  overflow: hidden;
  position: relative;
  max-width: 640px;
  margin: 0 0 3%;
  padding: 0;
  a {
    color: #fff;
  }
  @include breakpoint($sm) {
    &:hover {
      .featured-news-story__image img {
        transform: scale(1.25);
      }
      .featured-news-story__teaser {
        display: block;
      }
    }
  }
  @include breakpoint($md) {
    max-width: none;
    width: 48%;
    float: left;
  }
}

.featured-news-story.views-row-odd {
  @include breakpoint($md) {
    margin-right: 3%;
  }
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.25);
}

.featured-news-story__image {
  overflow: hidden;
}

.featured-news-story__image img {
  transition: all 0.3s ease-in-out;
}

.featured-news-story__title {
  color: #fff;
}

.featured-news-story  .news-story__date {
  color: #fff;
}

.featured-news-story .story-details {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 30px;
  z-index: 100;
}

.featured-news-story__teaser {
  display: none;
}

/* =News Feed
----------------------------------------------------------*/

.feeds-query-builder .views-exposed-form .views-exposed-widget {
  float: none !important;
  margin: 0 0 20px;
}

.feeds-query-builder .views-exposed-form label {
  display: block;
}

.feeds-query-builder .views-operator {
  margin: 0 0 20px;
}

.feeds-query-builder .select2-container {
  width: 70% !important;
  border-color: #ddd;
}

.feeds-query-builder .select2-container--default .select2-selection--multiple {
  border-color: #ddd;
}

.feeds-query-builder .views-operator .select2-container {
  width: 40% !important;
}





