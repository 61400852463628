/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
/**
 * Styles for various Newsroom components
 *
 */
/* =Primary Navigation
----------------------------------------------------------*/
#primary-nav .level-1 {
  font-size: 13px;
  width: 16%;
  line-height: 1.3; }
  @media screen and (min-width: 992px) {
    #primary-nav .level-1 {
      width: 16.66667%;
      font-size: 14px; } }
  @media screen and (min-width: 1200px) {
    #primary-nav .level-1 {
      font-size: 16px; } }

#primary-nav .menu-mlid-1949 {
  width: 10%; }
  @media screen and (min-width: 992px) {
    #primary-nav .menu-mlid-1949 {
      width: 9%; } }

#primary-nav .menu-mlid-1487 {
  width: 24%; }
  @media screen and (min-width: 992px) {
    #primary-nav .menu-mlid-1487 {
      width: 22%; } }
  @media screen and (min-width: 1200px) {
    #primary-nav .menu-mlid-1487 {
      width: 18%; } }

/* =Sidebar
----------------------------------------------------------*/
.newsroom-cta-social .wcm-cta__tile {
  width: auto;
  height: 60px;
  border: none;
  float: none; }

.newsroom-cta-social .wcm-cta__tile .cta-icon {
  width: 30px;
  height: 30px; }

.wcm-newsroom-home-social-media-icons .wcm-cta__tile:after,
.newsroom-cta-social .wcm-cta__tile:after {
  box-shadow: none; }

/* =Doormat Pane
----------------------------------------------------------*/
.pane-bundle-doormat {
  background: #f7f7f7; }
  .pane-bundle-doormat .pane-title {
    font-size: 24px;
    text-align: center;
    margin: 0 0 30px; }

.pane-bundle-doormat .container {
  padding: 20px 30px 30px; }
  @media screen and (min-width: 992px) {
    .pane-bundle-doormat .container {
      padding: 20px 15px 20px; } }

.press-doormat:before, .press-doormat:after {
  content: " ";
  display: table; }

.press-doormat:after {
  clear: both; }

.press-doormat h3 > a:before {
  content: '';
  display: block;
  width: 50px;
  height: 50px;
  float: left;
  margin-right: 20px;
  background-repeat: no-repeat;
  background-image: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg);
  background-size: 970px 285px; }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .press-doormat h3 > a:before {
      float: none;
      margin: 0 auto 20px; } }
  @media screen and (min-width: 1200px) {
    .press-doormat h3 > a:before {
      float: left;
      margin-right: 10px; } }

.press-doormat .doormat-entry--1 h3 > a:before {
  background-position: -193px -58px; }

.press-doormat .doormat-entry--2 h3 > a:before {
  background-position: -193px 1px; }

.press-doormat .doormat-entry--3 h3 > a:before {
  background-position: -133px -58px; }

.press-doormat .doormat-entry__description {
  padding-left: 60px; }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .press-doormat .doormat-entry__description {
      padding-left: 0; } }

/* =Spotlights
----------------------------------------------------------*/
.field-name-field-basic-spotlight-items {
  margin: 0 0 20px; }

.pane-bundle-spotlight .panopoly-spotlight-wrapper {
  max-width: none;
  width: 100%; }

.pane-bundle-spotlight .panopoly-spotlight-info {
  padding: 10px 20px 15px; }

.pane-bundle-spotlight .panopoly-spotlight-wrapper p {
  line-height: 1.5; }

/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px; }

/* =Forms
----------------------------------------------------------*/
.form-select {
  max-width: 600px; }

.views-exposed-form .views-exposed-widget {
  float: none; }
  @media screen and (min-width: 768px) {
    .views-exposed-form .views-exposed-widget {
      float: left; } }

.form-group .select2-container {
  min-width: 110px; }

.views-widget-filter-field_story_type_target_id .select2-container {
  min-width: 280px; }

.views-widget-filter-field_story_mission_target_id .select2-container {
  min-width: 170px; }

@media screen and (min-width: 768px) {
  .webform-component .select2-container {
    max-width: 50%; } }

.webform-component-time .form-select {
  display: inline;
  background-image: none;
  padding-right: 0; }
  @media screen and (max-width: 767px) {
    .webform-component-time .form-select {
      font-size: 13px;
      padding: 5px; } }
  @media screen and (min-width: 768px) {
    .webform-component-time .form-select {
      width: 80px; } }

.webform-component-time .radio input[type="radio"] {
  margin-top: 14px;
  margin-left: -16px; }

.webform-container-inline.webform-datepicker div.form-item.form-type-select {
  display: none; }

#edit-title-wrapper label {
  display: block; }

@media screen and (min-width: 768px) {
  #edit-title {
    width: 500px; } }

#edit-submit-news-stories {
  margin-top: 30px; }

.view-news-stories #autocomplete {
  font-size: 13px; }

/* =Footer
----------------------------------------------------------*/
.footer-site-info__office {
  font-weight: 700; }

@media screen and (min-width: 992px) {
  .footer-nav {
    margin: 0 0 20px; } }

@media screen and (min-width: 992px) {
  .footer-nav .level-1 {
    width: 16.66667%;
    float: left;
    padding: 0 10px 5px; } }

/* =Misc
----------------------------------------------------------*/
.social-share__print a:before {
  content: '\e814'; }

.btn--dark-orange {
  border-radius: 20px;
  font-size: 13px;
  background-color: #cf4520;
  color: #fff;
  display: inline-block; }
  .btn--dark-orange:hover, .btn--dark-orange:focus {
    background-color: #b31b1b;
    border-color: #b31b1b; }

.btn--mobile-app span {
  display: block;
  float: left;
  background-repeat: no-repeat;
  background-image: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg);
  background-size: 395px 120px;
  background-position: -50px -25px;
  width: 20px;
  height: 20px; }

.btn--mobile-app:hover span {
  display: none; }

.horizontal-tabs {
  clear: both; }

.row.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0; }

.pager--infinite-scroll li a {
  border-radius: 20px;
  border: 4px solid #e7751d;
  padding: 6px 20px;
  font-size: 13px;
  width: 220px; }
  .pager--infinite-scroll li a:hover, .pager--infinite-scroll li a:focus {
    border-color: #b31b1b;
    background-color: #cf4520;
    color: #fff; }

/**
 * Styles related to the Video and Photography section
 *
 */
/* =Photo Sets
----------------------------------------------------------*/
.photo-set-list [class^="col-"],
.photo-set-list [class*=" col-"] {
  padding-right: 0;
  padding-left: 0; }

.photo-set {
  margin: 0 0 30px; }

@media screen and (min-width: 992px) {
  .photo-set:nth-child(odd) {
    clear: both;
    padding-right: 10px; } }

@media screen and (min-width: 992px) {
  .photo-set:nth-child(even) {
    padding-left: 10px; } }

.photo-set__title {
  margin-top: 5px; }

/* =Vimeo Albums
----------------------------------------------------------*/
.pane-bundle-wcmc-vimeo, .page-node-56 .pane-bundle-video {
  margin-bottom: 40px;
  padding-bottom: 50px;
  border-bottom: 1px solid #dddddd; }

/* =News Story Nodes
----------------------------------------------------------*/
.social-share a {
  border: none; }

.pane-node-field-story-deck,
.pane-node-field-photo-story-deck {
  font-size: 20px;
  color: #666666;
  line-height: 1.3;
  padding-bottom: 10px; }

.pane-node-field-story-post-date,
.related-news h3 {
  text-transform: uppercase;
  color: #666666;
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 13px;
  letter-spacing: 2px;
  padding-bottom: 10px; }

.pane-node-field-story-featured-image {
  margin: 25px 0; }

.related-news {
  margin: 40px 0;
  padding: 10px 20px 20px;
  border: 1px solid #e7751d; }

/* =News Story Index
----------------------------------------------------------*/
.pane-news-stories-panel-pane-1 h3 {
  margin-top: 0; }

.select2-results__option:first-child {
  font-style: italic;
  border-bottom: 2px solid #ddd; }

.news-story {
  max-width: 640px;
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #dddddd; }
  @media screen and (min-width: 992px) {
    .news-story {
      max-width: none; } }

@media screen and (min-width: 992px) {
  .news-story:last-child {
    border-bottom: none; } }

@media screen and (min-width: 992px) {
  .news-story__title {
    margin-top: 0; } }

.news-story__date {
  text-transform: uppercase;
  color: #666666;
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 13px;
  letter-spacing: 2px;
  margin: 5px 0 20px; }

.news-story__read-more {
  padding-top: 7.5px; }
  .news-story__read-more a:after {
    content: '\e80d';
    color: #e7751d;
    padding: 5px 0 0 10px;
    font-size: 60%;
    vertical-align: top;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    text-decoration: none;
    line-height: 1.5; }

.news-story__tags,
.story-tags {
  margin: 34px 0 0;
  font-size: 12px;
  background: url(../images/tag.png) left center no-repeat;
  background-size: 15px 15px;
  padding-left: 22px; }

.news-story__type {
  font-size: 12px;
  text-transform: uppercase;
  font-family: "1898Sans-Bold", sans-serif;
  padding: 5px 10px 0;
  margin-bottom: 3px;
  background: #cf4520;
  max-width: 640px; }
  .news-story__type a {
    color: #fff;
    border-bottom: none; }
    .news-story__type a:hover {
      color: #fff;
      text-decoration: none; }

/*
.news-story__type--1 {
  background: $wcm-bright-orange;
}

.news-story__type--4 {
  background: $wcm-yellow;
}*/
/* =Index Filters
----------------------------------------------------------*/
.view-news-stories .views-exposed-form {
  margin: 10px 0 60px; }
  .view-news-stories .views-exposed-form .widget-label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold; }

/* =Featured News Stories Tiles
----------------------------------------------------------*/
.featured-news-stories {
  margin: 0 0 10px; }
  .featured-news-stories:before, .featured-news-stories:after {
    content: " ";
    display: table; }
  .featured-news-stories:after {
    clear: both; }

.featured-news-story {
  overflow: hidden;
  position: relative;
  max-width: 640px;
  margin: 0 0 3%;
  padding: 0; }
  .featured-news-story a {
    color: #fff; }
  @media screen and (min-width: 768px) {
    .featured-news-story:hover .featured-news-story__image img {
      transform: scale(1.25); }
    .featured-news-story:hover .featured-news-story__teaser {
      display: block; } }
  @media screen and (min-width: 992px) {
    .featured-news-story {
      max-width: none;
      width: 48%;
      float: left; } }

@media screen and (min-width: 992px) {
  .featured-news-story.views-row-odd {
    margin-right: 3%; } }

.mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25); }

.featured-news-story__image {
  overflow: hidden; }

.featured-news-story__image img {
  transition: all 0.3s ease-in-out; }

.featured-news-story__title {
  color: #fff; }

.featured-news-story .news-story__date {
  color: #fff; }

.featured-news-story .story-details {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 30px;
  z-index: 100; }

.featured-news-story__teaser {
  display: none; }

/* =News Feed
----------------------------------------------------------*/
.feeds-query-builder .views-exposed-form .views-exposed-widget {
  float: none !important;
  margin: 0 0 20px; }

.feeds-query-builder .views-exposed-form label {
  display: block; }

.feeds-query-builder .views-operator {
  margin: 0 0 20px; }

.feeds-query-builder .select2-container {
  width: 70% !important;
  border-color: #ddd; }

.feeds-query-builder .select2-container--default .select2-selection--multiple {
  border-color: #ddd; }

.feeds-query-builder .views-operator .select2-container {
  width: 40% !important; }

/**
 * Styles related to Photo Stories
 *
 */
.photo-story {
  position: relative; }

.paragraphs-items-field-photo-story-set .cycle-slideshow {
  background: none; }

.paragraphs-items-field-photo-story-set .thumbnail-controller {
  background: #f7f7f7;
  padding: 30px;
  overflow: hidden; }
  .paragraphs-items-field-photo-story-set .thumbnail-controller:before, .paragraphs-items-field-photo-story-set .thumbnail-controller:after {
    content: " ";
    display: table; }
  .paragraphs-items-field-photo-story-set .thumbnail-controller:after {
    clear: both; }

.paragraphs-items-field-photo-story-set #thumbnail-pager a {
  display: inline-block;
  margin: 0 20px 10px 0; }

.photo-story__caption {
  margin: 40px 0; }

.paragraphs-items-field-photo-story-set .thumbnail-controller__wrap {
  float: left;
  position: relative;
  left: 50%; }

.paragraphs-items-field-photo-story-set .thumbnail-controller__wrap-inner {
  position: relative;
  left: -50%; }

.paragraphs-items-field-photo-story-set .thumbnail-controller__pager {
  display: block;
  float: left;
  padding: 0 10px; }
  @media screen and (min-width: 992px) {
    .paragraphs-items-field-photo-story-set .thumbnail-controller__pager {
      width: 5%; } }
  .paragraphs-items-field-photo-story-set .thumbnail-controller__pager:after {
    content: '\e802';
    color: #e7751d;
    font-size: 24px;
    vertical-align: top;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    text-decoration: none;
    line-height: 1.5; }

.paragraphs-items-field-photo-story-set .thumbnail-controller__pager.previous:after {
  content: '\e810'; }
  @media screen and (min-width: 992px) {
    .paragraphs-items-field-photo-story-set .thumbnail-controller__pager.previous:after {
      padding: 10px 10px 0 0; } }

.paragraphs-items-field-photo-story-set .thumbnail-controller__pager.next:after {
  content: '\e802'; }
  @media screen and (min-width: 992px) {
    .paragraphs-items-field-photo-story-set .thumbnail-controller__pager.next:after {
      padding: 10px 0 0 10px; } }

.paragraphs-items-field-photo-story-set #thumbnail-pager {
  display: none; }
  @media screen and (min-width: 992px) {
    .paragraphs-items-field-photo-story-set #thumbnail-pager {
      float: left;
      display: block;
      width: 90%; } }

.paragraphs-items-field-photo-story-set #mobile-slide-counter {
  float: left;
  padding: 3px 20px; }
  @media screen and (min-width: 992px) {
    .paragraphs-items-field-photo-story-set #mobile-slide-counter {
      display: none !important; } }

/* =Previous / Next Arrow Overlays
----------------------------------------------------------------------------------*/
.paragraphs-items-field-photo-story-set .cycle-prev, .paragraphs-items-field-photo-story-set .cycle-next {
  display: none; }
  @media screen and (min-width: 768px) {
    .paragraphs-items-field-photo-story-set .cycle-prev, .paragraphs-items-field-photo-story-set .cycle-next {
      position: absolute;
      top: 0;
      opacity: 0;
      filter: alpha(opacity=0);
      width: 10%;
      z-index: 1002;
      height: 100%;
      cursor: pointer; } }
  @media screen and (min-width: 992px) {
    .paragraphs-items-field-photo-story-set .cycle-prev, .paragraphs-items-field-photo-story-set .cycle-next {
      display: block; } }

.paragraphs-items-field-photo-story-set .cycle-prev {
  left: 0;
  background: url("/profiles/wcmc/modules/custom/wcmc_carousel/images/carousel-prev.png") 35% 20% no-repeat; }
  @media screen and (min-width: 1200px) {
    .paragraphs-items-field-photo-story-set .cycle-prev {
      background-position: 35% 30%; } }

.paragraphs-items-field-photo-story-set .cycle-next {
  right: 0;
  background: url("/profiles/wcmc/modules/custom/wcmc_carousel/images/carousel-next.png") 65% 20% no-repeat; }
  @media screen and (min-width: 1200px) {
    .paragraphs-items-field-photo-story-set .cycle-next {
      background-position: 35% 30%; } }

.paragraphs-items-field-photo-story-set .cycle-prev:hover,
.paragraphs-items-field-photo-story-set .cycle-next:hover {
  opacity: .7;
  filter: alpha(opacity=70); }

/**
 * Styles related to the social media section.
 *
 */
.wcm-newsroom-social-media-icons {
  margin: 20px 0; }
  .wcm-newsroom-social-media-icons:before, .wcm-newsroom-social-media-icons:after {
    content: " ";
    display: table; }
  .wcm-newsroom-social-media-icons:after {
    clear: both; }
  @media screen and (max-width: 991px) {
    .wcm-newsroom-social-media-icons .col-md-2 {
      width: 50%;
      float: left;
      padding-left: 0px;
      padding-right: 0px; } }

.wcm-cta__tile-large {
  border-width: 0;
  float: none;
  margin: 0 auto;
  width: 150px;
  height: 150px; }
  @media screen and (max-width: 767px) {
    .wcm-cta__tile-large {
      width: 140px;
      height: 140px; } }
  .wcm-cta__tile-large:nth-child(1) {
    border-width: 0; }
  .wcm-cta__tile-large .cta-icon {
    width: 75px;
    height: 75px; }
  .wcm-cta__tile-large:hover {
    border-width: 1px; }
  .wcm-cta__tile-large .wcm-cta__detail {
    color: #666666;
    font-size: 15px;
    line-height: 24px; }

.twitter-timeline {
  margin-bottom: 30px !important; }

/**
 *  Styles targeting the Staff Directory and Media Relations Contact Pane
 */
/* =Staff Teasers
----------------------------------------------------------*/
.staff__name {
  font-weight: 700; }

.staff__title,
.staff__phone,
.staff__email {
  font-size: 13px; }

.staff__beats {
  font-size: 12px;
  margin-top: 10px; }
  .staff__beats span {
    font-weight: 700; }

.staff-media-relations .grid-title,
.staff-media-relations .grid-item,
.staff-directory .grid-title,
.staff-directory .grid-item {
  margin-bottom: 20px; }

/* =Media Relations Pane
----------------------------------------------------------*/
.staff-media-relations {
  margin: 20px 0 40px;
  padding-bottom: 80px;
  border-bottom: 1px solid #dddddd; }

/* =Publications
----------------------------------------------------------*/
.publication {
  margin-bottom: 40px;
  padding-bottom: 50px;
  border-bottom: 1px solid #dddddd; }
  .publication:before, .publication:after {
    content: " ";
    display: table; }
  .publication:after {
    clear: both; }

.publication:last-child {
  padding-bottom: 0;
  border-bottom: none; }

.publication__edition {
  margin-bottom: 0; }

.publication__title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px; }

.publication__teaser {
  margin-bottom: 30px; }

.publication__download-pdf {
  font-size: 14px;
  float: left;
  margin-right: 30px; }
  .publication__download-pdf:before, .publication__download-pdf:after {
    content: " ";
    display: table; }
  .publication__download-pdf:after {
    clear: both; }

.button-icon {
  display: block;
  width: 30px;
  height: 30px;
  float: left;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-image: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg);
  background-size: 1700%; }

.button-icon--pdf {
  background-position: -33px -95px; }

.publication__view-online {
  font-size: 14px;
  float: left; }
  .publication__view-online:before, .publication__view-online:after {
    content: " ";
    display: table; }
  .publication__view-online:after {
    clear: both; }

.publication__view-online a.external-link:after {
  content: ''; }

.button-icon--online {
  background-position: -290px 0px; }

.view-publications .view-footer {
  margin-bottom: 20px; }

/* =In the Media
----------------------------------------------------------*/
.pane-in-the-media-panel-pane-1 .ex-media {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid #dddddd; }

.pane-in-the-media-panel-pane-1 .ex-media:last-child {
  border: none; }

.pane-in-the-media-panel-pane-2 h3.pane-title {
  font-size: 30px; }

.ex-media__image {
  max-width: 240px;
  margin: 10px 0; }

.ex-media__date {
  text-transform: uppercase;
  color: #666666;
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 13px;
  letter-spacing: 2px;
  margin: 20px 0; }

.ex-media__contact {
  font-size: 12px;
  margin-bottom: 20px; }

/**
 * Styles related to components on the Newsroom homepage.
 *
 */
/* =Home Layout
----------------------------------------------------------*/
.panel-newsroom-home .container-fluid {
  padding-left: 0;
  padding-right: 0; }

.front .main-info-section {
  margin: 40px 0; }

.front .btn--wcm {
  margin-top: 40px; }

/* =Home Callouts
----------------------------------------------------------*/
.home-callouts {
  overflow: hidden; }
  .home-callouts:before, .home-callouts:after {
    content: " ";
    display: table; }
  .home-callouts:after {
    clear: both; }

@media screen and (min-width: 768px) {
  .row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; } }

@media screen and (min-width: 768px) {
  .home-callouts__main {
    border-right: 1px solid #fff; } }

@media screen and (min-width: 768px) {
  .home-callouts__side {
    border-left: 1px solid #fff; } }

@media screen and (min-width: 768px) {
  .home-callout--two .home-featured-news-story {
    position: relative;
    top: -2px; } }

.panel-newsroom-home img {
  width: 100%;
  max-width: none;
  height: auto; }

.home-callout .mask:hover {
  background: rgba(0, 0, 0, 0.5); }

.home-featured-news-story {
  position: relative;
  margin-bottom: 2px; }
  @media screen and (min-width: 768px) {
    .home-featured-news-story {
      margin-bottom: 0; } }

.home-callout .featured-news-story__title {
  color: #fff;
  position: absolute;
  margin: 0 20px;
  z-index: 100; }

.home-callout--one .featured-news-story__title {
  bottom: 8%;
  left: 30px;
  text-shadow: 0 3px 9px #333; }
  @media screen and (min-width: 768px) {
    .home-callout--one .featured-news-story__title {
      font-size: 30px; } }

.home-callout--two .featured-news-story__title,
.home-callout--three .featured-news-story__title {
  bottom: 8%;
  left: 1%;
  text-shadow: 0 3px 9px #333; }
  @media screen and (min-width: 768px) {
    .home-callout--two .featured-news-story__title,
    .home-callout--three .featured-news-story__title {
      font-size: 16px; } }
  @media screen and (min-width: 992px) {
    .home-callout--two .featured-news-story__title,
    .home-callout--three .featured-news-story__title {
      font-size: 16px; } }

/* =Home Latest News Stories
----------------------------------------------------------*/
.pane-news-stories-panel-pane-6 h3 {
  margin: 0 0 20px; }

.home-latest-news-story {
  overflow: hidden;
  position: relative;
  max-width: 640px;
  margin: 0 0 3%;
  padding: 0; }
  @media screen and (min-width: 992px) {
    .home-latest-news-story {
      max-width: none;
      width: 48%;
      float: left; } }

@media screen and (min-width: 992px) {
  .home-latest-news-story.views-row-odd {
    margin-right: 3%; } }

.latest-news-story__image {
  overflow: hidden; }

.latest-news-story__image img {
  transition: all 0.3s ease-in-out; }
  .latest-news-story__image img:hover {
    transform: scale(1.25); }

.home-latest-news-story .featured-news-story__title {
  font-size: 15px;
  margin: 0; }
  .home-latest-news-story .featured-news-story__title a {
    color: #cf4520; }
    .home-latest-news-story .featured-news-story__title a:hover {
      color: #e7751d; }

.home-latest-news-story .news-story__date {
  margin: 10px 0 5px;
  font-size: 13px; }

.home-latest-news-stories .view-footer {
  clear: both; }

/* =Main Sidebar
----------------------------------------------------------*/
.main-sidebar {
  margin: 40px 0 0;
  font-size: 14px; }

.wcm-newsroom-home-social-media-icons {
  position: relative;
  border-top: 1px solid #dddddd;
  margin: 20px 0 0;
  padding: 20px 0 0; }
  .wcm-newsroom-home-social-media-icons h4 {
    margin-bottom: 20px; }
  .wcm-newsroom-home-social-media-icons .social-media-feeds:after {
    content: '\e80d';
    color: #e7751d;
    padding-left: 10px;
    font-size: 60%;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    text-decoration: none;
    line-height: 1.5; }
  @media screen and (min-width: 1200px) {
    .wcm-newsroom-home-social-media-icons .social-media-feeds {
      position: absolute;
      top: 40px;
      right: 80px; } }
  .wcm-newsroom-home-social-media-icons .wcm-cta__tile {
    width: 46px;
    height: 46px;
    border: none;
    margin-right: 5px; }
  .wcm-newsroom-home-social-media-icons .wcm-cta__tile .cta-icon {
    width: 35px;
    height: 35px; }
  .wcm-newsroom-home-social-media-icons .wcm-cta__detail {
    padding-top: 6px; }
